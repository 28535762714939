@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap);
@font-face {
  font-family: 'Neutraface';
  src: url(/static/media/NeutrafaceText-Bold.fa5d7a2b.woff) format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
.neutraface-font{
  font-family: 'Neutraface',serif;
}
* {
    max-height: 100000px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  *:focus {
    outline: none;
  }

  body {
    margin: 0;
    color: #000;
    min-width: 320px;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: none;
    font-weight: 400;
    font-family: "Roboto", sans-serif;
    background: #000;
    background-size: cover;
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  #wrapper {
    height: 100%
  }
  #main{
    width:100%;
    height: 100vh;

  }
  .relative {
    position: relative;
  }
  .w-100{
    width: 100%;
  }
  .fixed {
    position: fixed;
  }
  .img-responsive {
    width: 100%;
    height: auto;
    display: block;
  }
  .list-none {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .h-100{
    height: 100%;
  }
  .container.fluid{
    width:100%;
    max-width: none;
  }
  .container:after{
    display:block;
    clear: both;
    content: '';
  }
  .word-break{
    word-break: break-word;
  }
  .d-block {
    display: block;
  }
  img {
    vertical-align: top;
    border-style: none;
  }
  a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
  }

  ::-webkit-input-placeholder {
    color: #A3A3A3;
  }

  ::placeholder {
    color: #A3A3A3;
  }

  :-ms-input-placeholder {
    color: #A3A3A3;
  }

  ::-ms-input-placeholder {
    color: #A3A3A3;
  }
  textarea.form-control {
    height: auto;
  }
  textarea {
    overflow: auto;
    resize: vertical;
  }
  input[type=checkbox], input[type=radio] {
    box-sizing: border-box;
    padding: 0;
  }
  button:focus,
  textarea:focus,
  input:focus,
  select {
    outline: none;
  }
  button, select {
    text-transform: none;
  }
  button, input {
    overflow: visible;
  }
  button, input, select, textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
  }
  .d-none {
    display: none;
  }
  .overflow-hidden{
    overflow: hidden;
  }
  .rounded {
    border-radius: 100%;
  }
  .flex {
    display: flex;
  }
  .flex-wrap {
    flex-wrap: wrap;
  }
  .items-start {
    align-items: flex-start;
  }
  .items-end {
    align-items: flex-end;
  }
  .items-center {
    align-items: center;
  }
  .flex-text-right {
    justify-content: flex-end;
  }
  .flex-text-center {
    justify-content: center;
  }
  .column-direction {
    flex-direction: column;
  }
  .text-center {
    text-align: center;
  }
  .text-right {
    text-align: right;
  }
  .text-left {
    text-align: left;
  }

  .ml-auto {
    margin-left: auto;
  }
  .mt-auto {
    margin-top: auto;
  }
  .mr-auto {
    margin-right: auto;
  }
  .p-0{
    padding: 0;
  }
  .pr-0 {
    padding-right: 0;
  }
  .pt-0{
    padding-top: 0;
  }
  .pb-0{
    padding-bottom: 0;
  }
  .pl-0 {
    padding-left: 0;
  }
  .pointer {
    cursor: pointer;
  }
  .disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default;
  }
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus{
  -webkit-text-fill-color: #A3A3A3;
  -webkit-box-shadow: 0 0 0 1000px transparent inset;
  -webkit-transition: 5000s ease-in-out 0s;
  transition: 5000s ease-in-out 0s;
}
.hidden {
  display: none;
}
.required-field{
  color:#DB0000;
  font-style: normal;
  margin-left: 5px;
  font-size: 17px;
}
.anchor-tag{
  text-decoration: underline;
}

/*@media only screen and (min-device-width : 320px) and (max-device-width : 800px) and (orientation : landscape) {*/
/*  html:after{*/
/*    width: 100%;*/
/*    height: 100vh;*/
/*    content: 'For Better Experience Use Portrait View';*/
/*    position: fixed !important;*/
/*    inset: 0;*/
/*    color: #fff;*/
/*    background: #000;*/
/*    z-index: 999999;*/
/*    display: flex;*/
/*    align-items: center;*/
/*    justify-content: center;*/
/*    font-size: 18px;*/
/*    font-family: 'Neutraface',serif;*/
/*    letter-spacing: 2px;*/
/*    line-height: 1.2;*/
/*  }*/
/*}*/

#root { height: 100% }
html,
body {
  position: relative;
  height: 100%;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.inner-content p {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.3;
  max-width: 430px;
  margin: 0 0 32px;
}

.inner-content .form-group {
  margin: 0 0 20px;
}

.inner-content label {
  color: #fff;
  display: inline-block;
  font-size: 14px;
  text-align: left;
  letter-spacing: .1em;
  margin: 0 0 12px;
  cursor: pointer;
}

.inner-content .success-message {
  color: green;
}

.inner-content .btn-holder {
  grid-gap: 25px;
  gap: 25px;
}

.inner-content .btn-holder button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.inner-content .btn-holder img {
  margin-right: 10px;
  max-width: 25px;
}

.wallet-list li {
  padding: 15px 30px;
  cursor: pointer;
  background: #1E1628;
  margin: 0 0 20px;
}

.wallet-list span {
  color: #fff;
  grid-gap: 10px;
  gap: 10px;
  text-transform: uppercase;
}

.wallet-list span img {
  max-width: 30px;
}

.congratulation p {
  max-width: 100%;
  font-size: 20px;
}

.congratulation img {
  max-width: 150px;
  margin: 0 0 36px;
}

.custom-checkbox{
  margin: 0 0 20px;
}
.custom-checkbox label {
  padding-left: 35px;
  color: #fff;
  margin: 0;
}
.custom-checkbox label:after {
  width: 20px;
  height: 20px;
  position: absolute;
  content: '';
  left: 0;
  top: -2px;
  background: #0A0113;
  border: 1px solid rgba(255, 255, 255, 0.16);
  border-radius: 0;
}
input[type="checkbox"]:checked+label:after{
  background:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAIbSURBVHgBbVRNaxRBEH3V21ljWCP4lZsKCWIMhvwc/Q0KevEimEMgEK8exbsHr148e/KgXvxYEwOLhwh+rbqoxJlMpbuquqdnyS5Md1XXvHr1qnro6Y1Pja8P0Kv/k68OuFdX1KtqBJ/sZ8LqwuqDr1ebvwq27IOv0tWBwh8MxAW6A+ne7NbAlMEUwzk+fIjkMoqINJgiXAhMq7oskTw4oYUdezklzd3JzulNNpqJObd0SIM4xLmYjS2Nkz1nSDtQQCmXWNhxy4jEBzgGZxZNpEkdFZC5cuTG1KCZlkkQXS451SPI1NFcbU148toS5q8vdbJQAhLl2eqVVjCVkmks8cLdVZy+tQI6MSNcsnYitvKQigSBct+yRm7gcX59jebWTmH8aIjJw/fwBeNYgtf0lCVdvn0Bc2c8trd2qNmruX9uFhfvXaHjiwN8e/AOk8cf4akUT/DYcUtdzr48H6N/9hiWNy5jcHWeFu+vUn9hFp+33mD8ZGRMs6JkJrk0RSnB95e/8XZjR+xLmyvohbJGd17j17M9lAoXPxlYV8BrJ4P9Z/QXw/UP/PPFD2zffIV/u5PUvfQuoFNvJpMXPEoKtQruf92n3c2hXkwbgXLmtGuc592VNZedMgZTI9D9kd2ECBAnO5EQJ2XqR+qB4gLb0Go5jopXdYL07hhtu+fELWw65vQ5YBXbTnMJlsouJ6uPpTMJMH1eOE824xBMrhoKTB4M7gAAAABJRU5ErkJggg==) no-repeat center;
  border-color:transparent;
}

.downarrow {
  position: relative;
  left: 50%;
  z-index: 1;
  text-align: center;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  bottom: 70px;
}
.downarrow:after{
  content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAgCAYAAAASYli2AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAE/SURBVHgB7VbttYIwDA2eNwBvg74NeBt1hG6gG+gGsIE6gW6AToAboBPERFOpSEvUfx7vOZePNL1NCycJwAAQMSc64pLYYIdGbBa0EKE2EOHnHXHbszejwuRQBiIzYjHgUxCrQHgeE5uLQ000ML4TExxH2R+0wTZyUELOupa5bmglk5hoEpG2wjyMrkxEwlHUifHZLUq6bOSlSEw48C4S40Y0NhmHysYsy35Tgnwjn7+Ez0VnQuSPcIT3cWKtH6Vzq/QDlSBt9V/7O01ACRJVHYtaUIuv4FfwIwVV6UuDMH3tiNEUrxTj5MzJY8+Ca7FbeB1O7pUvQL7IGHgSQfpvbvPx2i34mvxsGfUV0/YHq4eVxiPzNXkRc1pghxLjrcgUuz6nGlvZ4n3H1UqprfGxiXKghQivpCZ7HMTmYmd9BmIx+SSvgYTeAAAAAElFTkSuQmCC);
  position: absolute;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  left: 50%;
  -webkit-animation: animateArrow 1s linear infinite;
          animation: animateArrow 1s linear infinite;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-direction: alternate;
          animation-direction: alternate;
  top: -36px;
}
.down-arrow-img {
  width: 20px !important;
  margin-inline: auto;
  
}
@-webkit-keyframes animateArrow{
  0% {margin-top: 0px;}
  100% {margin-top: -15px;}
}
@keyframes animateArrow{
  0% {margin-top: 0px;}
  100% {margin-top: -15px;}
}
@media (max-height: 700px){
  .downarrow {
    bottom: 50px;
  }
}
@media(max-width: 768px) {
  .downarrow{
      position: absolute;
      top: calc(100vh - 120px);
  }
}
@media(max-width: 600px) {
  .wallet-list span {
    font-size: 12px;
  }
  .congratulation p {
    font-size: 18px;
  }
  .wallet-list li {
    padding: 15px 15px;
  }
}

.uppercase{
  text-transform: uppercase;
}

