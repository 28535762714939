@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');
@font-face {
  font-family: 'Neutraface';
  src: url('../fonts/neutraface/NeutrafaceText-Bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
.neutraface-font{
  font-family: 'Neutraface',serif;
}
* {
    max-height: 100000px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  *:focus {
    outline: none;
  }

  body {
    margin: 0;
    color: #000;
    min-width: 320px;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: none;
    font-weight: 400;
    font-family: "Roboto", sans-serif;
    background: #000;
    background-size: cover;
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  #wrapper {
    height: 100%
  }
  #main{
    width:100%;
    height: 100vh;

  }
  .relative {
    position: relative;
  }
  .w-100{
    width: 100%;
  }
  .fixed {
    position: fixed;
  }
  .img-responsive {
    width: 100%;
    height: auto;
    display: block;
  }
  .list-none {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .h-100{
    height: 100%;
  }
  .container.fluid{
    width:100%;
    max-width: none;
  }
  .container:after{
    display:block;
    clear: both;
    content: '';
  }
  .word-break{
    word-break: break-word;
  }
  .d-block {
    display: block;
  }
  img {
    vertical-align: top;
    border-style: none;
  }
  a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
  }

  ::placeholder {
    color: #A3A3A3;
  }

  :-ms-input-placeholder {
    color: #A3A3A3;
  }

  ::-ms-input-placeholder {
    color: #A3A3A3;
  }
  textarea.form-control {
    height: auto;
  }
  textarea {
    overflow: auto;
    resize: vertical;
  }
  input[type=checkbox], input[type=radio] {
    box-sizing: border-box;
    padding: 0;
  }
  button:focus,
  textarea:focus,
  input:focus,
  select {
    outline: none;
  }
  button, select {
    text-transform: none;
  }
  button, input {
    overflow: visible;
  }
  button, input, select, textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
  }
  .d-none {
    display: none;
  }
  .overflow-hidden{
    overflow: hidden;
  }
  .rounded {
    border-radius: 100%;
  }
  .flex {
    display: flex;
  }
  .flex-wrap {
    flex-wrap: wrap;
  }
  .items-start {
    align-items: flex-start;
  }
  .items-end {
    align-items: flex-end;
  }
  .items-center {
    align-items: center;
  }
  .flex-text-right {
    justify-content: flex-end;
  }
  .flex-text-center {
    justify-content: center;
  }
  .column-direction {
    flex-direction: column;
  }
  .text-center {
    text-align: center;
  }
  .text-right {
    text-align: right;
  }
  .text-left {
    text-align: left;
  }

  .ml-auto {
    margin-left: auto;
  }
  .mt-auto {
    margin-top: auto;
  }
  .mr-auto {
    margin-right: auto;
  }
  .p-0{
    padding: 0;
  }
  .pr-0 {
    padding-right: 0;
  }
  .pt-0{
    padding-top: 0;
  }
  .pb-0{
    padding-bottom: 0;
  }
  .pl-0 {
    padding-left: 0;
  }
  .pointer {
    cursor: pointer;
  }
  .disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default;
  }
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus{
  -webkit-text-fill-color: #A3A3A3;
  -webkit-box-shadow: 0 0 0 1000px transparent inset;
  transition: 5000s ease-in-out 0s;
}
.hidden {
  display: none;
}
.required-field{
  color:#DB0000;
  font-style: normal;
  margin-left: 5px;
  font-size: 17px;
}
.anchor-tag{
  text-decoration: underline;
}

/*@media only screen and (min-device-width : 320px) and (max-device-width : 800px) and (orientation : landscape) {*/
/*  html:after{*/
/*    width: 100%;*/
/*    height: 100vh;*/
/*    content: 'For Better Experience Use Portrait View';*/
/*    position: fixed !important;*/
/*    inset: 0;*/
/*    color: #fff;*/
/*    background: #000;*/
/*    z-index: 999999;*/
/*    display: flex;*/
/*    align-items: center;*/
/*    justify-content: center;*/
/*    font-size: 18px;*/
/*    font-family: 'Neutraface',serif;*/
/*    letter-spacing: 2px;*/
/*    line-height: 1.2;*/
/*  }*/
/*}*/
