.inner-content p {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.3;
  max-width: 430px;
  margin: 0 0 32px;
}

.inner-content .form-group {
  margin: 0 0 20px;
}

.inner-content label {
  color: #fff;
  display: inline-block;
  font-size: 14px;
  text-align: left;
  letter-spacing: .1em;
  margin: 0 0 12px;
  cursor: pointer;
}

.inner-content .success-message {
  color: green;
}

.inner-content .btn-holder {
  gap: 25px;
}

.inner-content .btn-holder button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.inner-content .btn-holder img {
  margin-right: 10px;
  max-width: 25px;
}

.wallet-list li {
  padding: 15px 30px;
  cursor: pointer;
  background: #1E1628;
  margin: 0 0 20px;
}

.wallet-list span {
  color: #fff;
  gap: 10px;
  text-transform: uppercase;
}

.wallet-list span img {
  max-width: 30px;
}

.congratulation p {
  max-width: 100%;
  font-size: 20px;
}

.congratulation img {
  max-width: 150px;
  margin: 0 0 36px;
}

.custom-checkbox{
  margin: 0 0 20px;
}
.custom-checkbox label {
  padding-left: 35px;
  color: #fff;
  margin: 0;
}
.custom-checkbox label:after {
  width: 20px;
  height: 20px;
  position: absolute;
  content: '';
  left: 0;
  top: -2px;
  background: #0A0113;
  border: 1px solid rgba(255, 255, 255, 0.16);
  border-radius: 0;
}
input[type="checkbox"]:checked+label:after{
  background:url('../../../src/assets/images/checked.png') no-repeat center;
  border-color:transparent;
}

.downarrow {
  position: relative;
  left: 50%;
  z-index: 1;
  text-align: center;
  transform: translateX(-50%);
  bottom: 70px;
}
.downarrow:after{
  content: url(../../assets/images/downarrow.png);
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  animation: animateArrow 1s linear infinite;
  animation-fill-mode: both;
  animation-direction: alternate;
  top: -36px;
}
.down-arrow-img {
  width: 20px !important;
  margin-inline: auto;
  
}
@keyframes animateArrow{
  0% {margin-top: 0px;}
  100% {margin-top: -15px;}
}
@media (max-height: 700px){
  .downarrow {
    bottom: 50px;
  }
}
@media(max-width: 768px) {
  .downarrow{
      position: absolute;
      top: calc(100vh - 120px);
  }
}
@media(max-width: 600px) {
  .wallet-list span {
    font-size: 12px;
  }
  .congratulation p {
    font-size: 18px;
  }
  .wallet-list li {
    padding: 15px 15px;
  }
}

.uppercase{
  text-transform: uppercase;
}
